.Portfolio {
	position: absolute;
	margin: 0 0 0 0;
	height: 100vh;
	width: 100vw;
	z-index: -4;
	background-color: var(--background-color);
}

#logo {
	position: absolute;
	top: 40%;
	left: 50%;
	width: var(--size-50);
	height: var(--size-50);
	transform: translate(-50%, -50%);
	opacity: 0;
	animation: logo-anim 2.5s forwards ease;
	animation-delay: 1s;
}

@keyframes logo-anim {
	33% {
		top: 40%;
		opacity: 1;
	}
	100% {	
		top: 30%;
		opacity: 1;
	}
}
@keyframes fade-in {
	100% {
		opacity: 1;
	}
}

#welcome-text {
	opacity: 0;
	margin: 0;
	padding: 0;
	color: white;
	background: none;
	font: var(--size-30) "Outfit";
	animation: fade-in 1s forwards ease;
	animation-delay: 1.5s;
}

#loading-back {
	stroke: #FFFFFF50;
}

#loading-part {
	animation: rotate-loading 1s both ease infinite;
	animation-delay: 3s;
	transform-origin: 25px 25px;
}

#loading {
	opacity: 0;
	margin: 0;
	padding: 0;
	width: var(--size-30);
	height: var(--size-30);
	animation: fade-in 0.5s ease 2.5s forwards;
}
#loading-flex {
	display: flex;
	position: absolute;
	flex-direction: column;
	align-items: center;

	margin-top: clamp(42px, 8.4vh, 84px);

	width: 100%;
	gap: clamp(42px, 8.4vh, 84px);

	opacity: 0;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	animation: fade-in 1s forwards;	
}

@keyframes rotate-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

#background {
	position: absolute;
	height: 100vh;
	width: 100vw;
	transform: rotate(180deg);
	background-position: center;
	background-size: cover;
	z-index: -1;
	background-image: url("./../../public/images/background.jpg");
	background-repeat: repeat;
}

#opacity-bg {
	position: absolute;
	height: 100vh;
	width: 100vw;
	z-index: 0;
	background: linear-gradient(90deg, rgba(0,0,0,0.9) 40%, rgba(0,0,0,0.1) 100%);
}

#screensaver {
	height: 100vh;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	opacity: 0;
	animation: fade-in 0.5s forwards;
}

@keyframes fade-in {
	100% {
		opacity: 1;
	}
}

#date-logo {
	width: var(--size-30);
	height: var(--size-30);
}

#lock-logo {
	width: var(--size-40);
	height: var(--size-40);
}

#dates {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	margin-left: 20px;
	z-index: 1;
}

#time {
	margin: 0;
	padding: 0;
	font: var(--size-50) "Outfit";
	color: white;
}
#date {
	margin-top: -45px;
	padding: 0;
	font: var(--size-20) "Outfit";
	color: rgba(255, 255, 255, 0.5);
}

#login-text {
	font: var(--size-30) "Outfit";
	color: white;
}

.break {
	flex-basis: 100%;
	height: 0;
}

#desktop-flex {
	opacity: 0;
	animation: fade-in 0.5s forwards;	
}

#lock-flex {
	margin-top: auto;
	display: flex;
	z-index: 1;
	align-items: center;
}


#login-flex {
	position: absolute;
	opacity: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-60%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 15px;
	z-index: 1;
	animation: fade-in 0.5s forwards;
}

/* #profile-pic {
	width: var(--size-100);
	height: var(--size-100);

	border-radius: 50%;
	z-index: 2;
	background-image: url('./../../public/images/pdp2.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border: 3px solid var(--red);
} */

#profile-name {
	color: white;
	font: var(--size-30) "Outfit";
}

#password-flex {
	padding: 0;
	margin-top: clamp(0px, 12vh, 120px);
	margin-left: calc(var(--size-70) / 2 + 5px);
	display: flex;
	width: calc(var(--size-50) * 5);
	flex-direction: row;
	align-items: center;
}
#password-input {
	background: none;
	flex-basis: 1;
	margin-bottom: 5px;
	border: none;
	border: 3px solid transparent;
	border-radius: 5px;
	border-bottom: 3px solid var(--red);
	transition: border 0.2s;

	height: 100%;
	width: 100%;

	color: white;
	font: var(--size-20) "Outfit";
	text-align: center;
}
#password-input:focus {
	outline: none;
	border-top: 3px solid var(--red);
	border-left: 3px solid var(--red);
	border-right: 3px solid var(--red);
}

#password-eye {
	margin-left: 10px;
	width: var(--size-50);
	height: var(--size-50);
	transform: scale(0);
	transition: transform 0.2s;
}

#password-input:focus ~ #password-eye {
	transform: scale(1);
}

#inner-eye {
	transition: 0.5s;
}
#password-eye:hover > #inner-eye {
	ry: 2px;
}
#password-eye:hover {
	transform: scale(1.25);
}

#login-button {
	margin-top: 20px;
	width: var(--size-100); 
	height: var(--size-30);
	border-radius: 5px;
	color: var(--red);
	border: none;
	font: var(--size-20) "Outfit";
	text-align: center;
	transform: scale(1);
	background-color: var(--black);
	transition: background-color 0.4s;
	animation: button-login-attract 0.75s 2.5s infinite both ease;
}

@keyframes button-login-attract {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

#login-button:hover {
	background-color: #2D2424;
}