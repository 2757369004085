.OpenedState {
	position: absolute;
	bottom: 0%;
	margin-left: 25px;
	transition: transform 0.2s, opacity 0.2s;
	opacity: 0;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: white;
	z-index: 1;
}

.AppBar {
	display: flex;
	margin: 0;
	padding: 0;

	width: clamp(500px, 65vw, 700px);
	height: 70px;

	background-color: var(--black);
	box-shadow: 5px 5px 5px #00000020;
	border-radius: 25px;

	justify-content: space-evenly;
	align-items: center;
}

.App {
	position: relative;
	display: inline-block;
	transition: transform 0.2s;
	width: auto;
	height: 60px;
	background-repeat: no-repeat;
	background-color: rgba(0, 0, 0, 0);
	border: none;
}

.Icon {
	font: 8px "Outfit";
}

#app-bar-flex {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	padding: 0;
	bottom: 0;
	left: 50%;
	gap: 15px;
	transform: translate(-50%, -25%);
}

#app-name-text {
	margin: 0;
	padding: 0;
	font: var(--size-40) "Outfit";
	color: var(--text-color);
	opacity: 0;
}

.App:hover ~ .OpenedState {
	transform: translate(0, 25px)
}
.App:hover {
	cursor: pointer;
	transform: scale(1.25, 1.25);
}

#cpp-plus-1 {
	animation: cpp-1-animation 1.5s ease;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}
#cpp-plus-2 {
	animation: cpp-2-animation 1.5s ease;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

#icon-cpp_projects:hover #cpp-plus-1 {
	animation-play-state: running;
}
#icon-cpp_projects:hover #cpp-plus-2 {
	animation-play-state: running;
}

@keyframes cpp-1-animation {
	0% {
		d: path("M 11.5,12.5 H14.5 M 13,14 V11");
	}
	25% {
		d: path("M 11.5,15.5 H14.5 M 13,17 V14");	
	}
	50% {
		d: path("M 16,15.5 H19 M 17.5,17 V14");	
	}
	75% {
		d: path("M 16,12.5 H19 M 17.5,14 V11");		
	}
	100% {
		d: path("M 11.5,12.5 H14.5 M 13,14 V11");	
	}
}
@keyframes cpp-2-animation {
	0% {
		d: path("M 16,12.5 H19 M 17.5,14 V11");
	}
	25% {
		d: path("M 16,10 H19 M 17.5,11.5 V8.5");	
	}
	50% {
		d: path("M 11.5,10 H14.5 M 13,11.5 V8.5");	
	}
	75% {
		d: path("M 11.5,12.5 H14.5 M 13,14 V11");		
	}
	100% {
		d: path("M 16,12.5 H19 M 17.5,14 V11");	
	}
}

#dice-die-1 {
	fill: var(--black);
	z-index: 1;
}
#dice-die-2 {
	fill: var(--black);
	z-index: 2;
}

g {
	transition: opacity 200ms;	
}

#icon-cub3d:hover .scene .cube {
	animation-play-state: running;
}

#icon-cub3d {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
}

.scene {
	width: 22px;
	height: 22px;
	perspective: 600px;
	grid-row-start: 1;
	grid-column-start: 1;
}
#cub-svg {
	height: 60px;
	width: auto;
	grid-row-start: 1;
	grid-column-start: 1;
}

.cube {
	width: 100%;
	height: 100%;
	left: 90%;
	top: 90%;
	position: absolute;
	transform-style: preserve-3d;
	transform-origin: center;
	transform: rotateX(-35deg);
	animation: rotate-cube 4s ease;
	animation-play-state: paused;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

.cube__face {
	border: 2px solid var(--black);
	background-color: var(--red);
	position: absolute;
	width: 15px;
	height: 15px;
}

.cube__face--front  { transform: rotateY(45deg)  translateZ(9px); }
.cube__face--right  { transform: rotateY(135deg)  translateZ(9px); }
.cube__face--back   { transform: rotateY(225deg)  translateZ(9px); }
.cube__face--left   { transform: rotateY(-45deg)  translateZ(9px); }
.cube__face--top    { transform: rotateY(45deg) rotateX( 90deg) translateZ(9px); }
.cube__face--bottom { transform: rotateY(45deg) rotateX(-90deg) translateZ(9px); }

@keyframes rotate-cube {
	from {
		transform: rotateX(-35deg);
	}
	to {
		transform: rotateX(-394deg);
	}
}

#pong-player-1 {
	animation: player-1-animation 1.5s ease;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

#pong-player-2 {
	animation: player-2-animation 1.5s ease;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

#pong-ball {
	animation: ball-animation 1.5s linear;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

#icon-pong:hover #pong-player-1 {
	animation-play-state: running;
}
#icon-pong:hover #pong-player-2 {
	animation-play-state: running;
}
#icon-pong:hover #pong-ball {
	animation-play-state: running;
}


@keyframes player-1-animation {
	0% {
		d: path("M 6,8 6,12");
	}
	75% {
		d: path("M 6,13 6,17");
	}
	100% {
		d: path("M 6,8 6,12");
	}
}
@keyframes player-2-animation {
	0% {
		d: path("M 18,13 18,17");
	}
	50% {
		d: path("M 18,7 18,11");
	}
	100% {
		d: path("M 18,13 18,17");
	}
}
@keyframes ball-animation {
	0% {
		d: path("M 12,12 12,12");
	}
	25% {
		d: path("M 16,9 16,9");
	}
	50% {
		d: path("M 12,13 12,13");
	}
	75% {
		d: path("M 8,16 8,16");
	}
	100% {
		d: path("M 12,12 12,12");
	}
}
#ServerDot1 {
	animation: slide-left-right1 0.75s ease-in;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}
#ServerDot2 {
	animation: slide-left-right2 0.75s ease-in;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

#icon-server_projects:hover #ServerDot1 {
	animation-play-state: running;
}
#icon-server_projects:hover #ServerDot2 {
	animation-play-state: running;
}

@keyframes slide-left-right1 {
	0% {
		d: path("M 9,11 9,11");
	}
	50% {
		d: path("M 15,11 15,11");
	}
	100% {	
		d: path("M 9,11 9,11");
	}
}
@keyframes slide-left-right2 {
	0% {
		d: path("M 9,15 9,15");
	}
	50% {
		d: path("M 15,15 15,15");
	}
	100% {	
		d: path("M 9,15 9,15");
	}
}

#about-me-info {
	animation: about-me-bounce 0.5s ease-in-out;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

#about-me-i {
	animation: about-me-shake 1.5s ease;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

#icon-about-me:hover #about-me-info {
	animation-play-state: running;
}
#icon-about-me:hover #about-me-i {
	animation-play-state: running;
}

@keyframes about-me-bounce {
	0% {
		d: path("M 12,8 12,8");
	}
	50% {
		d: path("M 12,10.5 12,10.5");
	}
	100% {
		d: path("M 12,8 12,8");
	}
}
@keyframes about-me-shake {
	0% {
		d: path("M 11,12 H12 V16 H13");
	}
	35% {
		d: path("M 13,12 H12 V16 H11");
	}
	100% {
		d: path("M 11,12 H12 V16 H13");
		
	}

}

#settings-cog {
	animation: rotating-cog 3s linear;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-play-state: paused;
	transform-origin: center;
}

#icon-settings:hover #settings-cog {
	animation-play-state: running;
}

@keyframes rotating-cog {
	0% {
		transform: rotateZ(0)
	}
	100% {
		transform: rotateZ(360deg)
	}
}
