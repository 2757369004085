@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@300&display=swap');
:root {
	--background-color: #1E1F24;
	--red: #F4615A;
	--yellow: #DDB955;
	--green: #439775;
	--blue: #3D5467;
	--black: #282935;
	--text-color: #FFFFFF;

	--size-100: clamp(60px, 12vw, 120px);
	--size-90: clamp(54px, 10.8vw, 108px);
	--size-80: clamp(48px, 9.6vw, 96px);
	--size-70: clamp(42px, 8.4vw, 84px);
	--size-60: clamp(36px, 7.2vw, 72px);
	--size-50: clamp(30px, 6vw, 60px);
	--size-40: clamp(24px, 4.8vw, 48px);
	--size-30: clamp(18px, 3.6vw, 36px);
	--size-20: clamp(12px, 2.4vw, 24px);

	--default-win-width: 600px;
	--default-win-height: 400px;
}

@font-face {
	font-family: Pixeloid;
	src: url('./../../public/assets/fonts/PixeloidSans-JR6qo.ttf')
}

.Canvas {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

button {
	cursor: pointer;
	transition: transform 150ms;
}

