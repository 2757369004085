.Background {
	background-color: var(--background-color);
	width: 100%;
	height: 100%;
	z-index: -1;
}

.title {
	display: flex;

	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;

	top: -50%;
	width: 200px;
	position: relative;

	flex-direction: row;
	justify-content: space-evenly;

	animation: title-goes-down 4.5s forwards ease;
}

@keyframes title-goes-down {
	40% {
		top: 15%;
		width: 200px;
	}
	100% {
		top: 15%;
		width: 800px;	
	}
}

.letter {
	position: relative;
	border-radius: 25px;
	font: 120px "Outfit";
	color: var(--red);
	width: 150px;
	height: 170px;
	text-align: center;
}

.letter.portfolio {
	background-color: var(--black);
	box-shadow: 5px 5px 5px #00000020;
	width: 10px;
	overflow: hidden;
	color: rgba(0,0,0,0);
	height: 125px;
	font-size: 100px;
	animation: show-full-title 1.5s forwards ease;
	animation-delay: 2.5s;
}

@keyframes show-full-title {
	100% {
		color: var(--red);
		width: 500px;
	}
}

.clickEffect {
	position: fixed;
	box-sizing: border-box;
	border: 1px solid var(--red);
	border-radius: 50%;
	animation: clickEffect 0.35s ease;
	z-index: 1;
}

@keyframes clickEffect {
	0% {
		opacity: 1;
		width: 0.5em;
		height: 0.5em;
		margin: -0.25em;
		border-width: 0.5em;
	}
	100% {
		opacity: 0.2;
		width: 5em;
		height: 5em;
		margin: -2.5em;
		border-width: 0.03em;
	}
}
