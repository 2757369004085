.ButtonFlex {
	margin-top: 2px;
	display: flex;
	width: 145px;
	height: 40px;
	right: 0%;
	justify-items: center;
	justify-content: space-evenly;
}

.WindowButton {
	border: none;
	background: none;
	cursor: pointer;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	z-index: 2;
}

svg path { transition: 0.2s; }
.icon-tabler-minimize:hover .minimizeButtonPath1 { d: path("M14 18v-2a2 2 0 0 1 2 -2h2") }
.icon-tabler-minimize:hover .minimizeButtonPath2 { d: path("M14 6v2a2 2 0 0 0 2 2h2") }
.icon-tabler-minimize:hover .minimizeButtonPath3 { d: path("M6 14h2a2 2 0 0 1 2 2v2") }
.icon-tabler-minimize:hover .minimizeButtonPath4 { d: path("M6 10h2a2 2 0 0 0 2 -2v-2") }

.icon-tabler-maximize:hover .maximizeButtonPath1 { d: path("M2 6v-2a2 2 0 0 1 2 -2h2") }
.icon-tabler-maximize:hover .maximizeButtonPath2 { d: path("M2 18v2a2 2 0 0 0 2 2h2") }
.icon-tabler-maximize:hover .maximizeButtonPath3 { d: path("M18 2h2a2 2 0 0 1 2 2v2") }
.icon-tabler-maximize:hover .maximizeButtonPath4 { d: path("M18 22h2a2 2 0 0 0 2 -2v-2") }

.icon-tabler-square-off:hover .quitButtonPath1 { d: path("M 5,5 12,12") }
.icon-tabler-square-off:hover .quitButtonPath2 { d: path("M 19,19 12,12") }
.icon-tabler-square-off:hover .quitButtonPath3 { d: path("M 19,5 12,12") }
.icon-tabler-square-off:hover .quitButtonPath4 { d: path("M 5,19 12,12") }

.window-header {
	display: flex;
	position: relative;
	width: 100%;
	height: 35px;
	
	background-color: var(--black);
	outline: 2px solid var(--background-color);
	box-shadow: 5px 5px 5px #00000020;
}

.WindowTitle {
	width: 200px;
	margin: 0;
	text-align: center;

	font: 25px "Outfit";
	color: var(--text-color);
	border-radius: 5000px;
	z-index: 2;
	animation: showTitle 1s ease-in;
	flex-basis: 100%;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.content {
	margin: 0;
	width: 100%;
	height: calc(100% - 35px);
}

.Scrollbar {
	position: absolute;
	right: 0;
}

@keyframes showTitle {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.WindowDefault {
	position:absolute;
	display: block;
	margin: 0;

	min-width: 200px;
	min-height: 100px;
	
	background-color: var(--black);
	border: none;
	border: 1px solid #00000050;
	border-radius: 8px;
	box-shadow: 5px 5px 5px #00000020;
	opacity: 1;
	overflow: hidden;
}

.WindowSpawn 				{ animation: spawnWindow				0.5s ease forwards; }
.WindowQuit 				{ animation: quitWindow					0.5s ease forwards; }
.WindowMaximized	 		{ animation: maximizeWindow				0.5s ease forwards; }
.WindowMaximizedReverse		{ animation: maximizeWindowReverse		0.5s ease forwards; }
.WindowMinimized 			{ animation: minimizeWindow				0.5s ease forwards; }

@keyframes maximizeWindowReverse {
	0% {
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
}

@keyframes maximizeWindow {
	100% {
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
}

@keyframes minimizeWindow {
	0% {
		display: block;
		transform: scale(1);
	}
	99% {
		display: block;
		transform: scale(0);
	}
	100% {
		display: none;
		transform: scale(0);
	}
}

@keyframes quitWindow {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(0.5);
		opacity: 0;
	}
	100% {
		transform: scale(0.5);
		opacity: 0;
	}
}

@keyframes spawnWindow {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}